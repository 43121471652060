// extracted by mini-css-extract-plugin
export var blocksInRowsBlockContainerActive = "payload-module--blocks-in-rows-block-container-active--3afb5";
export var blocksInRowsInnerWrapper = "payload-module--blocks-in-rows-inner-wrapper--9db76";
export var blocksInRowsTitlesContainer = "payload-module--blocks-in-rows-titles-container--8881d";
export var blocksInRowsWithOneActiveBlocksContainer = "payload-module--blocks-in-rows-with-one-active-blocks-container--337e0";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--d63f0";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--a7c7b";
export var blocksInRowsWithOneActiveSingleBlockContainer = "payload-module--blocks-in-rows-with-one-active-single-block-container--0c4f7";
export var blocksInRowsWithOneActiveSubtitle = "payload-module--blocks-in-rows-with-one-active-subtitle--f0741";
export var blocksRedirectingToResourcesInnerWrapper = "payload-module--blocks-redirecting-to-resources-inner-wrapper--62754";
export var blocksRedirectingToResourcesTitlesContainer = "payload-module--blocks-redirecting-to-resources-titles-container--c229c";
export var contactButtonImageSection = "payload-module--contact-button-image-section--f01bc";
export var contactButtonImageSectionContent = "payload-module--contact-button-image-section-content--e1437";
export var contactButtonImageSectionCustomContainer = "payload-module--contact-button-image-section-custom-container--3150b";
export var contactButtonImageSectionImageContainer = "payload-module--contact-button-image-section-image-container--95f37";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--38369";
export var headerInnerWrapper = "payload-module--header-inner-wrapper--c6221";
export var headerStyles = "payload-module--header-styles--d6db2";
export var headerTitle = "payload-module--header-title--8dc0e";
export var meetCodilimeInnerWrapper = "payload-module--meet-codilime-inner-wrapper--a3fed";
export var nextLine = "payload-module--next-line--496c4";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--b8b4f";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--0aab1";
export var techStackSection = "payload-module--tech-stack-section--e33b6";
export var techStackSingleRow = "payload-module--tech-stack-single-row--7e3c0";
export var testimonialInnerWrapper = "payload-module--testimonial-inner-wrapper--fd6ef";
export var underHeaderInnerWrapper = "payload-module--under-header-inner-wrapper--dc691";
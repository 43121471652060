import React from 'react';
import { paths } from '@src/constants/pathsEnum';

import featuredImage from './images/featured-image.jpg';
import contactButtonImageSectionImage from '@commons/contact-button-image-section/images/image2.svg';
import Equinix from '@images/logos-partners/equinix-logo.svg';
import { javaScript, typeScript, go, cPlusPlus, python, kafka, java, nodeJS, rust } from '@images/logos-techstack';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'Backend Development Services | CodiLime Company',
  description:
    "Since 2011 we've supported companies by building a solid base for network solutions. Check out our backend services, and beat the market with our help.",
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: (
    <>
      Backend <br />
      development <br />
      services
    </>
  ),
  isBorder: false,
  classNames: {
    header: styles.headerStyles,
  },
};

export const sectionUnderHeaderProps = {
  textPartOne:
    "The backbone of every successful digital product is a well-designed, developed, and implemented backend. It handles the business logic of stored data and ensures that everything on your software product's",
  textPartTwo:
    '. Let our backend engineering teams help make your product secure, resilient, and high-performing, thanks to their twelve years of experience.',
  contactButtonLabel: 'Power up your backend',
  classNames: {
    innerWrapper: styles.underHeaderInnerWrapper,
  },
};

export const blocksInRowsWithOneActiveProps = {
  title: 'Backend development services in CodiLime',
  subtitle: (
    <>
      To astonish your users with a <b>flawless and blazing-fast working software product</b>, its foundations need to
      be reliable and strong. With a talented team of backend engineers and carefully-selected technologies, we will
      meet your business needs and deliver a product that will make waves on the market.
    </>
  ),
  blocks: [
    {
      title: 'Custom architecture',
      description: (
        <>
          Working closely with our clients <b>from the very beginning</b> of every new software development project, we
          can offer custom architecture and high-level design to deliver the highest quality product.
        </>
      ),
    },
    {
      title: 'Tailor-made implementation',
      description: (
        <>
          More complex projects might require a different approach and skill set. In such cases, the talent pool is
          often shallow. Our top-notch experts can support you in{' '}
          <b>implementing the backend, no matter if it is a web, cloud, or networking application</b>.
        </>
      ),
    },
    {
      title: 'ETL solutions',
      description: (
        <>
          ETL (extract, transform and load) solutions allow our specialists to{' '}
          <b>integrate multiple internal and external systems</b>, transform data and expose it within an API for our
          clients.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.blocksInRowsWithOneActiveSection,
    innerWrapper: styles.blocksInRowsWithOneActiveInnerWrapper,
    subtitleStyles: styles.blocksInRowsWithOneActiveSubtitle,
    singleBlockContainer: styles.blocksInRowsWithOneActiveSingleBlockContainer,
    blocksContainer: styles.blocksInRowsWithOneActiveBlocksContainer,
  },
};

export const blocksInRowsWithOneWiderProps = {
  title: 'More about our backend expertise',
  subtitle: (
    <>
      From <b>networking and infrastructure companies,</b> fintech businesses, and <b>AI/ML and cloud platforms</b> to
      monitoring platforms, SaaS platforms, flow control solutions, and technical startups – we work with diverse
      clients and business models. We support startups, large-scale companies, and multi-service providers.
    </>
  ),
  id: 'our-expertise',
  blocks: [
    {
      title: 'Custom solutions',
      description: (
        <>
          We build dedicated solutions for data aggregation and metrics visualization, drawing on various sources like
          monitoring tools or adapters for network devices collecting data via SNMP or SSH.
        </>
      ),
      background: '#062338',
    },
    {
      title: 'Easy integrations',
      description: (
        <>
          Our specialists make the installation path as easy and seamless as possible. To do so, they use metadata to
          automate the whole process.
        </>
      ),
      background: '#041C2E',
    },
    {
      title: 'Implementation for web applications',
      description: (
        <>
          We provide you with a complex backend implementation that covers the necessary server-side logic, making your
          future web application resistant and secure.
        </>
      ),
      background: '#122B3F',
    },
    {
      title: 'Market-standardized technologies on board',
      description: (
        <>
          Our tech stack solely consists of reliable and market-standardized technologies. You can rely on us if you
          need Rust, Go, Python, Java, TypeScript, JavaScript, and Node.js developers. Considering a different solution?
          Ask if we have it.
        </>
      ),
      background: '#053356',
    },
    {
      title: 'Cloud-based solutions',
      description: (
        <>
          Our specialists will support any commercial cloud-based projects. We do it all - Amazon Web Services AWS,
          Google Cloud Platform GCP, and Microsoft Azure.
        </>
      ),
      background: '#122B3F',
    },
    {
      title: 'Tailored approaches',
      description: (
        <>
          Depending on specific project requirements, our specialists can support you whether we are talking about
          serverless, function as a service (FaaS), or cloud-oriented approaches.
        </>
      ),
      background: '#041C2E',
    },
  ],
  classNames: {
    innerWrapper: styles.blocksInRowsInnerWrapper,
    blockContainerActive: styles.blocksInRowsBlockContainerActive,
    titlesContainer: styles.blocksInRowsTitlesContainer,
  },
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
      section: styles.contactButtonImageSection,
    },
  },
  title: <>Backend development services for network solutions</>,
  image: contactButtonImageSectionImage,
  imageAlt: 'Development services for networks',
  isH2: true,
  classNames: {
    customContent: styles.contactButtonImageSectionContent,
    imageContainer: styles.contactButtonImageSectionImageContainer,
    customContainer: styles.contactButtonImageSectionCustomContainer,
  },
};

export const testimonialProps = {
  id: 'testimonial',
  testimonial: {
    quote: `CodiLime’s software engineers have been a great addition to our team by bringing a unique combination of experience in Go and network domain knowledge. Apart from the code contributions & reviews, they have provided invaluable support and guidance to the team with technical decisions. Their feedback and insights have positively upleveled the quality and reliability of the software we’re building. We’re impressed with their professionalism, engagement, and the value they bring to day-to-day cooperation.`,
    author: 'Kristine Antons - Senior Software Engineering Manager',
    logo: Equinix,
    logoAlt: 'Equinix',
  },
  leftTitle: 'Check out what our clients say about us',
  classNames: {
    innerWrapper: styles.testimonialInnerWrapper,
  },
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: <>CodiLime&apos;s backend library</>,
    subtitle: (
      <>
        Looking for expert backend-related articles? Look no further. Check out our blog, where you&apos;ll find more of
        our developers&apos; publications.
      </>
    ),
    classNames: {
      innerWrapper: styles.blocksRedirectingToResourcesInnerWrapper,
      titlesContainer: styles.blocksRedirectingToResourcesTitlesContainer,
    },
  },
  blocks: [
    {
      text: 'Rust vs. Go – what do you need to know about these programming languages?',
      link: '/blog/rust-vs-go-what-do-you-need-to-know-about-these-programming-languages/',
    },
    {
      text: 'Rust vs. Python: which would suit your project better?',
      link: '/blog/rust-vs-python-which-would-suit-your-project-better/',
    },
    {
      text: 'Why is the Python programming language so popular?',
      link: '/blog/why-is-the-python-programming-language-so-popular/',
    },
    {
      text: 'Dealing with private repositories, Go modules, Docker and CircleCI',
      link: '/blog/dealing-with-private-repositories-go-modules-docker-circleci/',
    },
    {
      text: 'Trunk-based development: everything you need to know',
      link: '/blog/trunk-based-development/',
    },
    {
      text: 'Rust projects – why do large IT companies use Rust?',
      link: '/blog/rust-projects-why-large-it-companies-use-rust/',
    },
  ],
};

export const coloredBlocksWithRedirectsProps = {
  title: 'Backend is just the beginning. Check out our other services!',
  subtitle:
    'The backend is just one piece of the puzzle that is a successful software product. See what our engineering teams can do for you to help beat the market with your fully-fledged products.',
  isFlippable: true,
  blocks: [
    {
      text: (
        <>
          <span>UX/UI</span>
        </>
      ),
      link: paths.SERVICES.UX_UI_DESIGN,
      description: <>Discover UX that understands network engineering.</>,
    },
    {
      text: (
        <>
          <span>Frontend development </span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.FRONTEND,
      description: (
        <>
          Easy and intuitive interaction with your product thanks to support from our frontend engineers&apos; who
          understand network challenges.
        </>
      ),
    },
    {
      text: (
        <>
          <span>Custom software </span>
          <span>development services</span>
        </>
      ),
      link: paths.SERVICES.CUSTOM_DEVELOPMENT,
      description: (
        <>
          Building a product from scratch to create a unique project tailored to client requirements and market needs.
        </>
      ),
    },
    {
      text: (
        <>
          <span>DevOps services</span>
        </>
      ),
      link: paths.SERVICES.DEVOPS,
      description: (
        <>
          Support your systems development and maintenance with experienced DevOps engineers - whether it&apos;s
          on-premise, hybrid, or cloud infrastructure.
        </>
      ),
    },
  ],
};

export const opacityScrolledSliderProps = {
  title: 'Benefits of a well-designed backend for your software product',
  subtitle: (
    <>
      When a product is based on reliable backend development services, further modifications and scalability-related
      challenges will be easier, especially when it comes to network solutions that are more complex than regular
      applications. <br className={styles.nextLine} />
      Why is a quality backend worth your attention?
    </>
  ),
  blocksGap: 37,
  topOpacity: 0.3,
  bottomOpacity: 20,
  showOneBlock: true,
  textBlocks: [
    <>
      <span>High-performance and secure products</span> that amaze end-users with their quality.
    </>,
    <>
      <span>A limited number of bugs</span> at later stages of development and production – meaning faster
      time-to-market for the product.
    </>,
    <>
      Focus on developing <span>cross-platform code</span> from the project&apos;s beginning to make future products
      available to a broader audience.
    </>,
    <>
      <span>Standardized coding process and technologies</span> provide consistent, compiled, and easily-manageable
      code.
    </>,
  ],
  classNames: {
    innerWrapper: styles.opacitySliderInnerWrapper,
  },
};

export const techStackProps = {
  title: 'Our backend techstack',
  subtitle: 'You’ll find here our complete backend toolkit. Check out what technologies we trust:',
  rowsWithBlocks: [
    [go, cPlusPlus, python, java, rust],
    [javaScript, typeScript, nodeJS, kafka],
  ],
  classNames: {
    section: styles.techStackSection,
    innerWrapper: styles.techStackInnerWrapper,
    singleRow: styles.techStackSingleRow,
  },
};

export const meetCodilimeProps = {
  title: 'Meet CodiLime - our company in numbers',
  altText: 'CodiLime Company in numbers',
  classNames: {
    innerWrapper: styles.meetCodilimeInnerWrapper,
  },
};

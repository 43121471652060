import React from 'react';

import Layout from '@src/layouts';

import {
  MobileHeaderLayoutA,
  DesktopHeaderLayoutB,
  SectionUnderHeaderWithUnderline,
  BlocksInRowsWithOneActive,
  ContactButtonImageSection,
  BlocksRedirectingToResources,
  NewClientSingleTestimonial,
  MeetCodilimeSection,
  ColoredBlocksWithRedirects,
  OpacityScrolledSlider,
  BlocksInRowsWithOneWider,
  TechStackWithoutCategories,
} from '@commons/index';

import SEO from '@commons/SEO';

import {
  SEOProps,
  headerProps,
  sectionUnderHeaderProps,
  blocksInRowsWithOneActiveProps,
  contactButtonImageSectionProps,
  testimonialProps,
  blocksRedirectingToResourcesProps,
  coloredBlocksWithRedirectsProps,
  opacityScrolledSliderProps,
  blocksInRowsWithOneWiderProps,
  techStackProps,
  meetCodilimeProps,
} from '@pages-impl/backend-development/payload';

export default function BackendDevelopment(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Develop the backend of your software solution"
      {...props}
    >
      <SEO {...SEOProps} />
      <MobileHeaderLayoutA {...headerProps} />
      <DesktopHeaderLayoutB {...headerProps} />
      <SectionUnderHeaderWithUnderline {...sectionUnderHeaderProps} />
      <BlocksInRowsWithOneActive {...blocksInRowsWithOneActiveProps} />
      <BlocksInRowsWithOneWider {...blocksInRowsWithOneWiderProps} />
      <OpacityScrolledSlider {...opacityScrolledSliderProps} />
      <TechStackWithoutCategories {...techStackProps} />
      <ContactButtonImageSection {...contactButtonImageSectionProps} />
      <NewClientSingleTestimonial {...testimonialProps} />
      <BlocksRedirectingToResources {...blocksRedirectingToResourcesProps} />
      <ColoredBlocksWithRedirects {...coloredBlocksWithRedirectsProps} />
      <MeetCodilimeSection {...meetCodilimeProps} />
    </Layout>
  );
}
